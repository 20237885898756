import "./App.css";
import Clients from "./components/Clients";
import Footer from "./components/Footer";
import Header from "./components/Header";
import LandingPage from "./components/LandingPage";
import Portfolio from "./components/Portfolio";
import Services from "./components/Services";
import Background from "./components/UI/Background";

function App() {
  return (
    <div className="App">
      <Background />
      <Header />
      <main>
        <LandingPage />
        <Services />
        <Clients />
        <Portfolio />
        {/* <Plans /> */}
      </main>
      <Footer />
    </div>
  );
}

export default App;
